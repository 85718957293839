(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-helper/assets/javascripts/normalize-player-competitions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-helper/assets/javascripts/normalize-player-competitions.js');
"use strict";


const normalizePlayerCompetitions = playerCompetitions => {
  if (playerCompetitions && playerCompetitions.length > 0) {
    const structuredPlayerCompetitions = {};
    playerCompetitions.forEach(competition => {
      structuredPlayerCompetitions[competition.competitionId] = competition;
    });
    return structuredPlayerCompetitions;
  }
  return null;
};
const exportObject = {
  normalizePlayerCompetitions
};
if (svs.isServer) {
  module.exports = exportObject;
} else {
  setGlobal('svs.components.marketplaceCompetition.competitionHelper.normalizePlayerCompetition', exportObject);
}

 })(window);